
import { Options, Vue } from 'vue-class-component'
import adminHome from '@/components/theAdminHome.vue'

  @Options({
    components: {
      adminHome
    }
  })
export default class adminhome extends Vue {}
